.full-name-mail-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .full-name-mail-field-container {
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
  }
}

.form-field {
  margin: 1.2rem 0;
  width: 100%;
}

.submit-button {
  align-self: center;
}
